/* eslint-disable react/prop-types */
import Link from "next/link";
import styles from "./bgBlue.module.scss";
import Image from "next/image";
import styleBtn from "../button.module.scss";
import classNames from "classnames";

type ButtonDefaultProps = {
  href: string;
  txt: string;
  icon?: boolean;
  src?: string;
  as?: string;
  i?: string;
  right?: boolean;
};

const ButtonDefault: React.FC<ButtonDefaultProps> = ({ href, as, txt, icon, src, i, right }) => {
  const classes = classNames((styleBtn.btn, styles.type3), {
    [styles.directionRight]: right
  });
  return (
    <Link href={href} as={as} className={classes}>
      {src && icon == true ? <Image src={src} alt={txt} width={25} height={25} /> : ""}
      {i && <i className={`${i}`}></i>}
      <span>{txt}</span>
    </Link>
  );
};

export default ButtonDefault;
